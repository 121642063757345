/* latin */
@font-face {
  font-family: 'Gotham Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('./fonts/GOTHAM-PRO-BLACK.woff') format('woff');
}

/* latin */
@font-face {
  font-family: 'Gotham Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./fonts/GothamProBold.woff2') format('woff2');
  src: url('./fonts/GOTHAM-PRO-BOLD.woff') format('woff');
}
/* latin */
@font-face {
  font-family: 'Gotham Pro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./fonts/GothamPro.woff2') format('woff2');
  src: url('./fonts/GOTHAM-PRO-MEDIUM.woff') format('woff');
}

/* latin */
@font-face {
  font-family: 'Gotham Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./fonts/GOTHAM-PRO-REGULAR.woff') format('woff');
}

/* latin */
@font-face {
  font-family: 'Gotham Pro';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('./fonts/GOTHAM-PRO-LIGHT.woff') format('woff');
}